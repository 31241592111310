import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, Check } from 'lucide-react';
import useLazyImage from './useLazyImage';

const JobApplicationForm = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({});
  const [location, setLocation] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [postcodeError, setPostcodeError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isPostcodeValid, setIsPostcodeValid] = useState(false);
  const [jobFeatures, setJobFeatures] = useState([]);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [outcode, setOutcode] = useState('');
  const [jobImage, setJobImage] = useState('');
  const [jobAbout, setJobAbout] = useState('');

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const ReviewHighlight = ({ reviewCount, platform, message }) => {
  return (
    <div className="flex flex-col sm:flex-row items-start !important w-full mt-6">
      <div className="flex -space-x-2 mb-2 sm:mb-0 sm:mr-3 self-start !important">
        <img className="w-10 h-10 rounded-full border-2 border-white" src="images/profiles/one.jpg" alt="User 1" />
        <img className="w-10 h-10 rounded-full border-2 border-white" src="images/profiles/two.jpg" alt="User 2" />
        <img className="w-10 h-10 rounded-full border-2 border-white" src="images/profiles/three.jpg" alt="User 3" />
      </div>
      <p className="text-sm text-gray-600 mt-2 sm:mt-0 text-left !important">
        <span className="font-bold">Join {reviewCount}+ Care Professionals</span> {platform}. {message}
      </p>
    </div>
  );
};

  const IntroImage = ({ mobileImage, desktopImage }) => (
  <>
    <motion.div 
      className="absolute top-0 left-0 h-full w-full bg-cover bg-center bg-no-repeat rounded-[15px] lg:rounded-tr-[50px] lg:rounded-br-[50px] lg:rounded-tl-none lg:rounded-bl-none lg:hidden"
      style={{ backgroundImage: `url(${mobileImage})` }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    />
    <motion.div 
      className="absolute top-0 left-0 h-full w-full bg-cover bg-center bg-no-repeat rounded-[15px] lg:rounded-tr-[50px] lg:rounded-br-[50px] lg:rounded-tl-none lg:rounded-bl-none hidden lg:block"
      style={{ backgroundImage: `url(${desktopImage})` }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    />
  </>
);

  const [isPrivacyChecked, setIsPrivacyChecked] = useState(false);

  const getUTMParams = useCallback(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return {
      utm_campaign: urlParams.get('utm_campaign') || '',
      utm_medium: urlParams.get('utm_medium') || '',
      utm_source: urlParams.get('utm_source') || '',
      fbclid: urlParams.get('fbclid') || '',
      gclid: urlParams.get('gclid') || '',
      office: urlParams.get('office') || ''
    };
  }, []);

  const fetchJobDetails = useCallback(async (outcode) => {
  const urlParams = new URLSearchParams(window.location.search);
  const hasOffice = urlParams.has('office');

  try {
    const response = await fetch(`https://hook.eu2.make.com/xrpqdnfpqtnxnirg2wv699iwzeopfp1d?outcode=${outcode}`);
    
    if (response.ok) {
      const data = await response.text();
      
      if (data === 'redirect' && !hasOffice) {
        window.location.href = "https://join.homeinstead.co.uk";
        return;
      }
      
      if (data !== 'redirect') {
        const jsonData = JSON.parse(data);
        setShouldRedirect(false);
        setJobFeatures([
          jsonData.feature_one,
          jsonData.feature_two,
          jsonData.feature_three
        ].filter(Boolean));
        setJobImage(jsonData.image || '');
        setJobAbout(jsonData.about || '');
      } else if (hasOffice) {
        // Set default job features if 'redirect' but office is present
        setJobFeatures([
          'Flexible working hours',
          'Competitive pay',
          'No prior experience required'
        ]);
        setShouldRedirect(false);
      }
      setCurrentStep(prevStep => prevStep + 1);
    } else {
      throw new Error('Response not OK');
    }
  } catch (error) {
    console.error('Error fetching job details:', error);
    if (hasOffice) {
      // Set default job features if error occurs but office is present
      setJobFeatures([
        'Flexible working hours',
        'Competitive pay',
        'No prior experience required'
      ]);
      setShouldRedirect(false);
      setCurrentStep(prevStep => prevStep + 1);
    } else {
      setShouldRedirect(true);
      setJobFeatures([]);
    }
  } finally {
    setIsLoading(false);
  }
}, [setCurrentStep, setShouldRedirect, setJobFeatures, setJobImage, setJobAbout, setIsLoading]);

  const validatePostcode = useCallback(async (postcode) => {
    setIsLoading(true);
    try {
      const response = await fetch(`https://api.postcodes.io/postcodes/${postcode}`);
      const data = await response.json();
      if (response.status === 200) {
        setIsPostcodeValid(true);
        setLocation(data.result.admin_district || 'your area');
        setPostcodeError('');
        
        // Fetch job details immediately after successful validation
        await fetchJobDetails(data.result.outcode);
        
        // Move to the next step after fetching job details
        setCurrentStep(prevStep => prevStep + 1);
      } else {
        setIsPostcodeValid(false);
        setPostcodeError('Invalid postcode. Please try again.');
      }
    } catch (error) {
      console.error('Error looking up postcode:', error);
      setIsPostcodeValid(false);
      setPostcodeError('Error looking up postcode. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [fetchJobDetails]);




  const validatePhoneNumber = (phoneNumber) => {
  const mobileRegex = /^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/;
  const landlineRegex = /^(\+44\s?(\d{4}|\d{3})|\(?0\d{4}|\d{3}\)?)\s?\d{3}\s?\d{3,4}$/;
  return mobileRegex.test(phoneNumber) || landlineRegex.test(phoneNumber);
};

  const handlePrivacyCheckbox = useCallback((e) => {
    setIsPrivacyChecked(e.target.checked);
  }, []);


  const validateEmail = (email) => {
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return re.test(email);
};

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
    if (name === 'postcode') {
      setIsPostcodeValid(false);
      setPostcodeError('');
      debouncedValidatePostcode(value);
    }
  }, []);

  const debouncedValidatePostcode = useMemo(
    () => debounce((value) => {
      if (value.length >= 5) {
        validatePostcode(value);
      }
    }, 500),
    [validatePostcode]
  );


const steps = useMemo(() => [
    {
      id: 'intro1',
      title: 'Transform lives, including your own.',
      subtitle: "Imagine a job where your kindness brings comfort to older adults in their own homes. Whether you\’re sharing a cup of tea, helping with morning routines, or simply being a friendly face, every moment you spend with them makes a difference.",
      buttonText: 'Get started',
      mobile_image: '/images/mobile/one.avif',
      desktop_image: '/images/mobile/one.avif',
      type: 'intro',
      footerText: 'Ready to apply? ',
      footerLink: 'Click here',
      footerLinkId: 'apply-link',
      renderReviewComponent: () => (
      <ReviewHighlight 
        reviewCount={(10000).toLocaleString()} 
        platform="across the UK" 
        message=""
      />
    ),
      tickPoints: [
      'Choose part-time or full-time hours',
      'Full training provided—no experience needed'
    ]
    },
    {
      id: 'intro2',
      title: 'Become the highlight of their week',
      subtitle: "Every day is different, and there’s no need to rush. You'll take your time to truly connect, knowing it\'s the time with you they cherish most, while helping them live independently in the home they love.",
      buttonText: 'Next',
      mobile_image: '/images/mobile/two.avif',
      desktop_image: '/images/desktop/one.avif',
      type: 'intro',
      tickPoints: [
        'Unrushed 1-hour minimum visits',
        'Matched with clients who share your interests'
    ]
    },
    {
      id: 'intro3',
      title: 'Step into a role where you\’re always supported',
      subtitle: "Whether it\’s your first job in care or a new chapter in your life, we\’ll give you the training and support you need to make a real difference. Bring your kindness, and we\’ll take care of the rest.",
      buttonText: 'Get started',
      mobile_image: '/images/mobile/four.avif',
      desktop_image: '/images/desktop/three.avif',      type: 'intro',
      tickPoints: [
        'Ongoing support from your local office team',
        'Earn professional qualifications'
    ]
    },
    {
      id: 'postcode',
      title: "Let\’s find your perfect role",
      subtitle: "Caring opportunities available across the UK — find one near you.",
      type: 'postcode',
      tickPoints: [
      'Comprehensive training',
      'Earn professional qualifications'
    ]
    },
    {
      id: 'processing',
      title: "Finding local jobs...",
      type: 'processing'
    },
    {
      id: 'location',
      title: "Great news - we are recruiting in {location}!",
      type: 'info',
      infoPoints: [
        "Competitive pay that values your time and effort",
        "Full training to help you start with confidence",
        "A range of working hours available"
      ]
    },
    {
      id: 'describe',
      title: 'Tell us a little about yourself',
      subtitle: 'Select all that apply — we\’d love to get to know you better.',
      type: 'multiSelect',
      options: [
        'I enjoy helping others',
        'I want to make a difference',
        'I\'ve previously worked in care',
        'I\'ve cared for a friend or family member'
      ]
    },
    {
      id: 'availability',
      title: 'When are you available to make a difference?',
      subtitle: 'We have various hours that can fit into your life.',
      type: 'select',
      options: [
        'Up to 15 hours a week',
        'More than 15 hours a week',
        'I\'d like to discuss'
      ]
    },
    {
      id: 'interests',
      title: 'How would you like to make an impact?',
      subtitle: 'Explore the roles available — choose as many as you like.',
      type: 'multiSelect',
      options: [
        { label: 'Providing Home Care', description: 'Enjoy hourly visits with local clients' },
        { label: 'Overnight support', description: 'Stay overnight to support a client' },
        { label: 'Live-in care', description: 'Stay in a client\'s home to support their needs' }
      ]
    },
    {
      id: 'driving',
      title: 'Do you drive and have access to a car?',
      subtitle: 'Some clients may need assistance with local transport. We reimburse for mileage!',
      type: 'select',
      options: [
        'Yes, I drive',
        'No, I don\'t drive'
      ]
    },
    {
      id: 'personal_details',
      title: 'Let\’s get to know you',
      type: 'form',
      fields: [
        { name: 'firstName', label: 'First name', type: 'text' },
        { name: 'lastName', label: 'Last name', type: 'text' }
      ]
    },
    {
      id: 'contact',
      title: 'How can we reach you?',
      type: 'form',
      fields: [
        { name: 'email', label: 'Email address', type: 'email' },
        { name: 'phone', label: 'Phone number', type: 'tel' }
      ]
    },
    {
    id: 'thankYou',
    title: 'Thank you for applying!',
    subtitle: 'A local recruiter will be in touch soon to chat about what\’s next.',
    type: 'info'
  }
], []);

  // Lazy load images for second and third intro slides
  const intro2Image = useLazyImage(steps[1].imageUrl);
  const intro3Image = useLazyImage(steps[2].imageUrl);

  const handleApplyClick = useCallback(() => {
  const postcodeStepIndex = steps.findIndex(step => step.type === 'postcode');
  if (postcodeStepIndex !== -1) {
    setCurrentStep(postcodeStepIndex);
  }
}, [steps, setCurrentStep]);


  useEffect(() => {
  const step = steps[currentStep];
  let isValid = true;

  switch (step.type) {
    case 'form':
      if (step.id === 'personal_details') {
        isValid = step.fields.every(field => formData[field.name] && formData[field.name].trim() !== '');
      } else {
        isValid = step.fields.every(field => formData[field.name]);
      }
      break;
    case 'postcode':
      isValid = isPostcodeValid;
      break;
    case 'select':
    case 'multiSelect':
      isValid = selectedOptions[step.id]?.length > 0;
      break;
    default:
      isValid = true;
  }

  // Special handling for the contact step
  if (step.id === 'contact') {
    isValid = isValid && isPrivacyChecked;
  }

  setIsFormValid(isValid);
}, [formData, currentStep, steps, isPostcodeValid, selectedOptions, isPrivacyChecked]);


  const renderFormFields = (step) => {
  switch (step.type) {
    case 'form':
      return (
        <div className="flex flex-col gap-4">
          {step.fields.map((field, index) => (
            <motion.div 
              key={index}
              custom={index + 2}
              variants={cascadeVariants}
              initial="hidden"
              animate="visible"
            >
              <label htmlFor={field.name} className="block text-sm font-medium text-gray-700 mb-1">
                {field.label}{step.id === 'personal_details' && <span className="text-red-500">*</span>}
              </label>
              <input
                type={field.type}
                id={field.name}
                name={field.name}
                value={formData[field.name] || ''}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-custom-pink focus:border-custom-pink"
                required={step.id === 'personal_details'}
                autocomplete={
                  field.name === 'email' ? 'email' :
                  field.name === 'phone' ? 'tel' :
                  field.name === 'firstName' ? 'given-name' :
                  field.name === 'lastName' ? 'family-name' :
                  'off'
                }
              />
              {field.name === 'email' && emailError && (
                <p className="text-red-500 text-sm mt-1">{emailError}</p>
              )}
              {field.name === 'phone' && phoneError && (
                <p className="text-red-500 text-sm mt-1">{phoneError}</p>
              )}
            </motion.div>
          ))}
        </div>
      );

    case 'select':
    case 'multiSelect':
      return (
        <>
          {step.options.map((option, index) => (
            <motion.button
              key={index}
              custom={index + 2}
              variants={cascadeVariants}
              initial="hidden"
              animate="visible"
              type="button"
              onClick={() => handleOptionClick(step.id, typeof option === 'string' ? option : option.label)}
              className={`w-full text-left px-4 py-3 border rounded-lg mb-2 transition-all duration-200 ease-in-out ${
                selectedOptions[step.id]?.includes(typeof option === 'string' ? option : option.label)
                  ? 'border-custom-pink bg-custom-pink bg-opacity-10'
                  : 'border-gray-300 hover:bg-gray-50'
              }`}
            >
              {typeof option === 'string' ? option : (
                <>
                  <div>{option.label}</div>
                  {option.description && <div className="text-sm text-gray-500">{option.description}</div>}
                </>
              )}
            </motion.button>
          ))}
        </>
      );

    default:
      return null;
    }
  };

  const renderContinueButton = (customIndex) => (
  <motion.button
    custom={customIndex}
    variants={cascadeVariants}
    initial="hidden"
    animate="visible"
    onClick={handleNext}
    disabled={!isFormValid}
    className={`w-full mt-4 px-6 py-3 text-white text-lg rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-300 ${
      isFormValid ? 'bg-custom-pink hover:bg-opacity-90' : 'bg-gray-400 cursor-not-allowed'
    }`}
  >
    Continue →
  </motion.button>
);

  const renderContent = () => {
  const step = steps[currentStep];

  if (step.id === 'thankYou') {
    return (
      <motion.div
        className="w-full max-w-[28rem] mx-auto px-2 sm:px-0"
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={contentVariants}
        transition={{ duration: 0.5 }}
      >
        <motion.h2 
          custom={0}
          variants={cascadeVariants}
          initial="hidden"
          animate="visible"
          className="text-3xl font-bold text-gray-900 mb-4 text-center"
        >
          {step.title}
        </motion.h2>
        <motion.p 
          custom={1}
          variants={cascadeVariants}
          initial="hidden"
          animate="visible"
          className="text-center text-gray-600 mb-8"
        >
          {step.subtitle}
        </motion.p>
      </motion.div>
    );
  }

  if (step.id === 'contact') {
    return (
      <motion.div
        className="w-full max-w-[28rem] mx-auto px-2 sm:px-0"
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={contentVariants}
        transition={{ duration: 0.5 }}
      >
        <motion.h2 
          custom={0}
          variants={cascadeVariants}
          initial="hidden"
          animate="visible"
          className="text-3xl font-bold text-gray-900 mb-4 text-center"
        >
          {step.title}
        </motion.h2>
        {step.subtitle && (
          <motion.p 
            custom={1}
            variants={cascadeVariants}
            initial="hidden"
            animate="visible"
            className="text-center text-gray-600 mb-8"
          >
            {step.subtitle}
          </motion.p>
        )}

        {renderFormFields(step)}

        <motion.div
  custom={9}
  variants={cascadeVariants}
  initial="hidden"
  animate="visible"
  className="mt-4 flex items-start"
>
  <input
    type="checkbox"
    id="privacyCheck"
    checked={isPrivacyChecked}
    onChange={handlePrivacyCheckbox}
    className="h-5 w-5 rounded border-gray-300 text-custom-pink focus:ring-custom-pink cursor-pointer"
  />
  <div className="ml-3 text-sm">
    <label htmlFor="privacyCheck" className="text-gray-500 cursor-pointer">
      <span className="inline-block mr-1">🔒</span>
      We respect your privacy and will only use your information to contact you about this role. By submitting, you agree to our{' '}
      <a 
        href="https://www.applytocare.com/legal/privacy-policy" 
        target="_blank" 
        rel="noopener noreferrer"
        className="text-custom-pink hover:underline"
      >
        privacy notice
      </a>.
    </label>
  </div>
</motion.div>

        <motion.button
          custom={10}
          variants={cascadeVariants}
          initial="hidden"
          animate="visible"
          onClick={handleSubmit}
          disabled={!isPrivacyChecked}
          className={`w-full mt-4 px-6 py-3 text-white text-lg rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-300 ${
            isPrivacyChecked ? 'bg-custom-pink hover:bg-opacity-90' : 'bg-gray-400 cursor-not-allowed'
          }`}
        >
          Finish
        </motion.button>
      </motion.div>
    );
  }

  if (step.type === 'intro') {
  return (
    <div className="flex flex-col min-h-screen w-full h-full bg-[#fafafa] pb-8 sm:pb-0">
      {currentStep > 0 && (
        <div className="p-4 lg:hidden">
          <button
            onClick={handlePrev}
            className="text-gray-600 flex items-center focus:outline-none"
          >
            <ChevronLeft className="h-5 w-5 mr-1" />
            Back
          </button>
        </div>
      )}
      <div className={`flex flex-col lg:flex-row flex-grow ${currentStep > 0 ? 'mt-2 lg:mt-0' : ''}`}>
        <div className="relative w-full lg:w-[40%] h-64 lg:h-screen">
          <AnimatePresence mode="wait">
            <IntroImage 
              key={`${step.mobile_image}-${step.desktop_image}`}
              mobileImage={step.mobile_image}
              desktopImage={step.desktop_image}
            />
          </AnimatePresence>
        </div>

        <div className="relative w-full lg:w-[60%] flex items-center justify-center lg:justify-start px-4 lg:px-16 mt-4 lg:mt-0">
          {currentStep > 0 && (
            <button
              onClick={handlePrev}
              className="absolute top-8 left-16 text-gray-600 items-center focus:outline-none hidden lg:flex"
            >
              <ChevronLeft className="h-5 w-5 mr-1" />
              Back
            </button>
          )}
          <div className="w-full max-w-2xl text-left">
            <motion.h1 
              custom={0}
              variants={cascadeVariants}
              initial="hidden"
              animate="visible"
              className="text-4xl lg:text-[56px] font-bold text-gray-900 mb-4 leading-tight"
            >
              {step.title}
            </motion.h1>
            <motion.p 
              custom={1}
              variants={cascadeVariants}
              initial="hidden"
              animate="visible"
              className="text-lg text-gray-600 mb-8"
            >
              {step.subtitle}
            </motion.p>
            
            {step.tickPoints && step.tickPoints.length > 0 && (
              <motion.div 
                custom={2}
                variants={cascadeVariants}
                initial="hidden"
                animate="visible"
                className="mb-6"
              >
                {step.tickPoints.map((point, index) => (
                  <div key={index} className="flex items-start gap-2 mb-2">
                    <Check className="flex-shrink-0 h-5 w-5 text-custom-pink mt-1" />
                    <span>{point}</span>
                  </div>
                ))}
              </motion.div>
            )}
            
            <motion.button
              custom={3}
              variants={cascadeVariants}
              initial="hidden"
              animate="visible"
              className="w-full md:w-auto px-6 py-3 bg-custom-pink text-white text-lg rounded-lg focus:outline-none focus:ring-2 focus:ring-custom-pink focus:ring-offset-2 transition-colors duration-300"
              onClick={handleNext}
            >
              {step.buttonText} →
            </motion.button>
            
            {/* Footer text with hyperlink */}
            {step.footerText && (
              <motion.div 
                custom={4}
                variants={cascadeVariants}
                initial="hidden"
                animate="visible"
                className="mt-6"
              >
                <span className="text-sm text-gray-600">
                  {step.footerText}
                  {step.footerLink && (
                    <button
                      id={step.footerLinkId}
                      onClick={handleApplyClick}
                      className="text-custom-pink hover:underline focus:outline-none"
                    >
                      {step.footerLink}
                    </button>
                  )}
                </span>
              </motion.div>
            )}
            
            {/* Review component */}
            {step.renderReviewComponent && (
              <motion.div
    custom={5}
    variants={cascadeVariants}
    initial="hidden"
    animate="visible"
  >
    <div className="mt-6">
      <div className="flex flex-col sm:flex-row items-start sm:items-center mt-4 sm:mt-6">
        <div className="flex -space-x-2 mb-2 sm:mb-0 sm:mr-3 self-start">
          <img className="w-10 h-10 rounded-full border-2 border-white" src="images/profiles/one.jpg" alt="Care Professional 1" />
          <img className="w-10 h-10 rounded-full border-2 border-white" src="images/profiles/two.jpg" alt="Care Professional 2" />
          <img className="w-10 h-10 rounded-full border-2 border-white" src="images/profiles/three.jpg" alt="Care Professional 3" />
        </div>
        <p className="text-sm text-gray-600 text-left">
          Join 10,000+ Care Professionals across the UK.
        </p>
      </div>
    </div>
  </motion.div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
  if (step.type === 'postcode') {
    return (
        <motion.div
          className="w-full max-w-[28rem] mx-auto px-2 sm:px-0"
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={contentVariants}
          transition={{ duration: 0.5 }}
        >
          <motion.h2 
            custom={0}
            variants={cascadeVariants}
            initial="hidden"
            animate="visible"
            className="text-3xl font-bold text-gray-900 mb-4 text-center"
          >
            {step.title}
          </motion.h2>
          <motion.p 
            custom={1}
            variants={cascadeVariants}
            initial="hidden"
            animate="visible"
            className="text-center text-gray-600 mb-8"
          >
            {step.subtitle}
          </motion.p>
          <motion.div
            custom={2}
            variants={cascadeVariants}
            initial="hidden"
            animate="visible"
          >
            <input
              type="text"
              name="postcode"
              placeholder="Enter your postcode"
              onChange={handleInputChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-custom-pink focus:border-custom-pink mb-4"
            />
            {postcodeError && <p className="text-red-500 text-sm mt-1 mb-4">{postcodeError}</p>}
          </motion.div>
          <motion.button
            custom={3}
            variants={cascadeVariants}
            initial="hidden"
            animate="visible"
            onClick={handleNext}
            disabled={!isPostcodeValid || isLoading}
            className={`w-full px-6 py-3 text-white text-lg rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-300 ${
              isPostcodeValid && !isLoading ? 'bg-custom-pink hover:bg-opacity-90' : 'bg-gray-400 cursor-not-allowed'
            }`}
          >
            {isLoading ? 'Searching...' : 'Continue →'}
          </motion.button>
        </motion.div>
    );
  }

  if (step.type === 'processing') {
    return (
      <motion.div
        className="w-full max-w-[28rem] mx-auto px-2 sm:px-0 pt-12"
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={contentVariants}
        transition={{ duration: 0.5 }}
      >
        <div className="flex flex-col items-center">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-custom-pink"></div>
          <p className="mt-4 text-lg">{step.title}</p>
        </div>
      </motion.div>
    );
  }

  if (step.type === 'info') {
    const urlParams = new URLSearchParams(window.location.search);
    const hasOffice = urlParams.has('office');

    return (
      <motion.div
        className="w-full max-w-[28rem] mx-auto px-2 sm:px-0"
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={contentVariants}
        transition={{ duration: 0.5 }}
      >
        {jobImage && (
          <motion.img 
            custom={0}
            variants={cascadeVariants}
            initial="hidden"
            animate="visible"
            src={jobImage} 
            alt="Job" 
            className="w-full h-64 object-cover rounded-lg mb-4" 
          />
        )}
        <motion.h2 
          custom={1}
          variants={cascadeVariants}
          initial="hidden"
          animate="visible"
          className="text-3xl font-bold text-gray-900 mb-4 text-center"
        >
          We are recruiting in {location}!
        </motion.h2>
        {jobAbout && (
          <motion.p 
            custom={2}
            variants={cascadeVariants}
            initial="hidden"
            animate="visible"
            className="text-gray-600 mb-4"
          >
            {jobAbout}
          </motion.p>
        )}
        <motion.div 
          custom={3}
          variants={cascadeVariants}
          initial="hidden"
          animate="visible"
          className="mb-8"
        >
          {jobFeatures.map((feature, index) => (
            <div key={index} className="flex items-center mb-2">
              <Check className="h-5 w-5 text-custom-pink mr-2" />
              <span>{feature}</span>
            </div>
          ))}
        </motion.div>
        <motion.button
          custom={4}
          variants={cascadeVariants}
          initial="hidden"
          animate="visible"
          className="w-full mt-4 px-6 py-3 bg-custom-pink text-white text-lg rounded-lg focus:outline-none focus:ring-2 focus:ring-custom-pink focus:ring-offset-2 transition-colors duration-300"
          onClick={shouldRedirect && !hasOffice ? () => window.location.href = "https://join.homeinstead.co.uk" : handleNext}
        >
          {shouldRedirect && !hasOffice ? 'Continue to apply →' : 'Continue →'}
        </motion.button>
      </motion.div>
    );
  }

  if (step.id === 'personal_details') {
    return (
      <motion.div
        className="w-full max-w-[28rem] mx-auto px-2 sm:px-0"
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={contentVariants}
        transition={{ duration: 0.5 }}
      >
        <motion.h2 
          custom={0}
          variants={cascadeVariants}
          initial="hidden"
          animate="visible"
          className="text-3xl font-bold text-gray-900 mb-4 text-center"
        >
          {step.title.replace('{location}', location)}
        </motion.h2>
        {step.subtitle && (
          <motion.p 
            custom={1}
            variants={cascadeVariants}
            initial="hidden"
            animate="visible"
            className="text-center text-gray-600 mb-8"
          >
            {step.subtitle}
          </motion.p>
        )}

        {renderFormFields(step)}

        {renderContinueButton(10)}

        <motion.div 
          custom={11}
          variants={cascadeVariants}
          initial="hidden"
          animate="visible"
          className="mt-6 p-4 bg-white rounded-lg shadow-sm"
        >
          <div className="flex items-center mb-4">
            <img src="images/profiles/testimonial.jpg" alt="Care Professional" className="h-10 w-10 rounded-full mr-3" />
            <div>
              <p className="text-sm font-bold text-gray-900">Nina K</p>
              <p className="text-xs text-gray-500">Care Professional</p>
            </div>
          </div>
          <p className="text-sm text-gray-700 mb-4">
            "You’ll get all the training you need, and with a supportive team in the office, that warm fuzzy feeling at the end of the day is guaranteed."
          </p>
          <div className="flex">
            {[...Array(5)].map((_, i) => (
              <svg key={i} className="h-4 w-4 text-yellow-500" fill="currentColor" viewBox="0 0 20 20">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.021 3.145a1 1 0 00.95.69h3.316c.969 0 1.371 1.24.588 1.81l-2.686 1.953a1 1 0 00-.364 1.118l1.021 3.145c.3.921-.755 1.688-1.54 1.118l-2.686-1.953a1 1 0 00-1.176 0l-2.686 1.953c-.785.57-1.84-.197-1.54-1.118l1.021-3.145a1 1 0 00-.364-1.118L2.97 8.572c-.783-.57-.38-1.81.588-1.81h3.316a1 1 0 00.95-.69l1.021-3.145z"></path>
              </svg>
            ))}
          </div>
        </motion.div>
      </motion.div>
    );
  }

if (step.type === 'select' || step.type === 'multiSelect') {
    return (
      <motion.div
        className="w-full max-w-[28rem] mx-auto px-2 sm:px-0"
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={contentVariants}
        transition={{ duration: 0.5 }}
      >
        <motion.h2 
          custom={0}
          variants={cascadeVariants}
          initial="hidden"
          animate="visible"
          className="text-3xl font-bold text-gray-900 mb-4 text-center"
        >
          {step.title.replace('{location}', location)}
        </motion.h2>
        {step.subtitle && (
          <motion.p 
            custom={1}
            variants={cascadeVariants}
            initial="hidden"
            animate="visible"
            className="text-center text-gray-600 mb-8"
          >
            {step.subtitle}
          </motion.p>
        )}
        {step.options.map((option, index) => (
          <motion.button
            key={index}
            custom={index + 2}
            variants={cascadeVariants}
            initial="hidden"
            animate="visible"
            type="button"
            onClick={() => handleOptionClick(step.id, typeof option === 'string' ? option : option.label)}
            className={`w-full text-left px-4 py-3 border rounded-lg mb-2 transition-all duration-200 ease-in-out ${
              selectedOptions[step.id]?.includes(typeof option === 'string' ? option : option.label)
                ? 'border-custom-pink bg-custom-pink bg-opacity-10'
                : 'border-gray-300 hover:bg-gray-50'
            }`}
          >
            {typeof option === 'string' ? (
              option
            ) : (
              <>
                <div className="font-medium">
                  {option.label}
                  {option.label === 'Providing Home Care' && (
                    <span className="text-custom-pink text-sm ml-2">(most popular)</span>
                  )}
                </div>
                {option.description && <div className="text-sm text-gray-500">{option.description}</div>}
              </>
            )}
          </motion.button>
        ))}
        {renderContinueButton(step.options ? step.options.length + 2 : 10)}
      </motion.div>
    );
  }


  // General case for other steps
  return (
    <motion.div
      className="w-full max-w-[28rem] mx-auto px-2 sm:px-0"
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={contentVariants}
      transition={{ duration: 0.5 }}
    >
      <motion.h2 
        custom={0}
        variants={cascadeVariants}
        initial="hidden"
        animate="visible"
        className="text-3xl font-bold text-gray-900 mb-4 text-center"
      >
        {step.title.replace('{location}', location)}
      </motion.h2>
      {step.subtitle && (
        <motion.p 
          custom={1}
          variants={cascadeVariants}
          initial="hidden"
          animate="visible"
          className="text-center text-gray-600 mb-8"
        >
          {step.subtitle}
        </motion.p>
      )}
      {renderFormFields(step)}
      {renderContinueButton(step.options ? step.options.length + 2 : 10)}
    </motion.div>
  );
};

const handleInputBlur = useCallback((e) => {
  const { name, value } = e.target;
  if (name === 'email') {
    if (!validateEmail(value)) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError('');
    }
  } else if (name === 'phone') {
    if (!validatePhoneNumber(value)) {
      setPhoneError('Please enter a valid phone number');
    } else {
      setPhoneError('');
    }
  }
}, []);

  const handleOptionClick = useCallback((stepId, option) => {
  setSelectedOptions(prevSelected => {
    if (stepId === 'driving') {
      return { ...prevSelected, [stepId]: [option] };
    }
    const currentSelection = prevSelected[stepId] || [];
    const newSelection = currentSelection.includes(option)
      ? currentSelection.filter(item => item !== option)
      : [...currentSelection, option];
    return { ...prevSelected, [stepId]: newSelection };
  });
}, []);


  const handleNext = useCallback(() => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(prevStep => prevStep + 1);
    }
  }, [currentStep, steps.length]);

  const handlePrev = useCallback(() => {
  if (currentStep > 0) {
    if (steps[currentStep].id === 'location') {
      setCurrentStep(steps.findIndex(step => step.id === 'postcode'));
    } else {
      setCurrentStep(prevStep => prevStep - 1);
    }
  }
}, [currentStep, steps]);

  const handleSubmit = useCallback(async () => {
  if (emailError || phoneError) {
    alert('Please correct the errors in the form before submitting.');
    return;
  }

  setIsSubmitting(true);
  const submissionData = steps.reduce((acc, step) => {
    if (step.type === 'select' || step.type === 'multiSelect') {
      acc[step.title] = selectedOptions[step.id] || [];
    } else if (step.type === 'form') {
      step.fields.forEach(field => {
        acc[field.label] = formData[field.name] || '';
      });
    } else if (step.type === 'postcode') {
      acc['Postcode'] = formData.postcode || '';
    }
    return acc;
  }, {});

  submissionData['Location'] = location;
  
  // Add UTM params
  Object.assign(submissionData, getUTMParams());

  try {
    const response = await fetch('https://hook.eu2.make.com/r5al9s8ixa62uf74zyvk7vx4qgoblbmh', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(submissionData),
    });
    if (response.ok) {
      setIsSubmitted(true);
      setCurrentStep(prevStep => prevStep + 1); // Move to thank you step
    } else {
      throw new Error('Submission failed');
    }
  } catch (error) {
    console.error('Error submitting form:', error);
    alert('There was an error submitting your application. Please try again.');
  } finally {
    setIsSubmitting(false);
  }
}, [
  emailError, 
  phoneError, 
  steps, 
  selectedOptions, 
  formData, 
  location, 
  getUTMParams, 
  setIsSubmitting, 
  setIsSubmitted, 
  setCurrentStep
]);

  const contentVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 }
  };

  const cascadeVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (custom) => ({
      opacity: 1,
      y: 0,
      transition: { delay: custom * 0.1, duration: 0.5, ease: "easeOut" }
    })
  };

    return (
      <div className="min-h-screen bg-[#fafafa] flex flex-col relative px-2 sm:px-0">
      {currentStep > 0 && steps[currentStep].type !== 'intro' && (
      <div className="absolute top-0 left-0 w-full">
        <div className="h-1 bg-gray-200 rounded-full overflow-hidden">
          <motion.div
            className="h-full bg-custom-pink"
            style={{ width: `${((currentStep + 1) / steps.length) * 100}%` }}
            initial={{ width: 0 }}
            animate={{ width: `${((currentStep + 1) / steps.length) * 100}%` }}
            transition={{ duration: 0.5 }}
          ></motion.div>
        </div>
      </div>
    )}

    {currentStep > 0 && steps[currentStep].type !== 'intro' && steps[currentStep].type !== 'processing' && steps[currentStep].id !== 'thankYou' && (
      <header className="p-4 flex items-center">
        <button
          onClick={handlePrev}
          className="text-gray-600 flex items-center focus:outline-none"
        >
          <ChevronLeft className="h-5 w-5 mr-1" />
          Back
        </button>
      </header>
    )}

    <div className={`flex-grow flex flex-col items-center justify-start ${steps[currentStep].id === 'thankYou' ? 'mt-16 sm:mt-24 lg:mt-32' : 'mt-2 sm:mt-8 lg:mt-0'}`}>
      <AnimatePresence mode="wait">
        {renderContent()}
      </AnimatePresence>
    </div>
    </div>
  );
};

export default JobApplicationForm;
