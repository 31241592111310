import { useState, useEffect } from 'react';

function useLazyImage(src) {
  const [source, setSource] = useState(null);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setSource(src);
  }, [src]);

  return source;
}

export default useLazyImage;
